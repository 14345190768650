import { ReactElement } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { avatars } from './utils';
import Popup from 'reactjs-popup';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import cn from 'classnames';

import styles from './avatar.module.scss';

type Props = {
  defaultAvatar: string;
  avatarLetters: string;
  onSubmit: (close: () => void, data: any) => void;
  trigger: ReactElement;
};

export const ChangeAvatarPopup = ({ defaultAvatar, avatarLetters, onSubmit, trigger }: Props) => {
  const { control, reset, handleSubmit } = useForm();

  const closeModal = (close) => {
    close();
    reset();
  };

  const watch = useWatch({ name: 'avatar', control });

  return (
    <Popup modal trigger={trigger} closeOnEscape repositionOnResize lockScroll closeOnDocumentClick>
      {(close) => (
        <PopupTemplate
          size="sm"
          dismissModal={closeModal.bind(undefined, close)}
          headerTitle={'Change avatar'}
          rightContent={
            <Container>
              <form onSubmit={handleSubmit(onSubmit.bind(undefined, () => closeModal(close)))}>
                <div className={styles.container}>
                  <div className={styles.avatars}>
                    {[{ id: avatarLetters, src: '' }].concat(avatars).map((avatar) => (
                      <div key={avatar.id}>
                        <Controller
                          control={control}
                          name="avatar"
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => {
                            const isDisabled = avatar.id === defaultAvatar;
                            const isSelected = field.value === avatar.id && !isDisabled;

                            return (
                              <>
                                <input
                                  type="radio"
                                  name={avatar.id}
                                  id={avatar.id}
                                  value={avatar.id}
                                  checked={isSelected}
                                  disabled={isDisabled}
                                  onChange={(e) => field.onChange(e.target.value)}
                                  style={{ display: 'none' }}
                                />
                                <label
                                  htmlFor={avatar.id}
                                  className={cn(
                                    styles.label,
                                    isSelected && styles.selected,
                                    isDisabled && styles.disabled,
                                  )}
                                >
                                  {!!avatar.src ? <img src={avatar.src} /> : avatarLetters}
                                </label>
                              </>
                            );
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.popupButtons}>
                  <Button
                    buttonType="outline"
                    buttonText="Cancel"
                    onClick={() => closeModal(close)}
                  />
                  <Button buttonText="Save" type="submit" disabled={!watch} />
                </div>
              </form>
            </Container>
          }
        />
      )}
    </Popup>
  );
};
