import React from 'react';
import EditNotePopup from './EditNotePopup';
import { deleteNote } from 'api/notes';
import { notify } from 'utils/notify';
import { usePermission } from 'utils/usePermission';
import { LeadNote } from 'models/Leads/LeadNotes';
import { Icon } from 'components/ui/Icon';
import { Note } from 'components/ui/Notes';

type Props = {
  noteItem: LeadNote;
  updateNotesList: () => void;
  updatePinnedNotes: () => void;
};

const NotesItem = (props: Props) => {
  const { noteItem, updateNotesList, updatePinnedNotes } = props;

  const { permissionGiven: PermissionNotesDelete } = usePermission('admin.notes.delete');

  const update = () => {
    Promise.all([updateNotesList(), updatePinnedNotes()]);
  };

  const removeNote = () => {
    deleteNote(noteItem.id)
      .then(() => {
        notify({
          type: 'success',
          message: 'Notes deleted successfully',
          timeOut: 3000,
        });
        update();
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  return (
    <Note
      note={noteItem}
      actions={
        <>
          <EditNotePopup
            updateComponent={update}
            noteData={noteItem}
            triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
          />

          {PermissionNotesDelete ? (
            <Icon name="trash" size={20} color="var(--red)" onClick={() => removeNote()} />
          ) : null}
        </>
      }
    />
  );
};
export default NotesItem;
