import { ReactNode } from 'react';
import { NoteOperatorName } from './NoteOperatorName';
import { NoteUuid } from './NoteUuid';
import { NoteDate } from './NoteDate';
import styles from './note.module.scss';

type Props = {
  note?: any;
  actions: ReactNode;
};

export const Note = ({ note, actions }: Props) => {
  return (
    <div className={styles.note}>
      <div className={styles.header}>
        <div>
          <NoteOperatorName operator={note?.operator} isPinned={note?.is_pinned} />

          {note ? <NoteUuid uuid={note?.operator?.uuid} /> : null}

          <NoteDate createdAt={note.create_at} to={note.to} />
        </div>
        <div className={styles.actions}>{actions}</div>
      </div>
      <div>
        <div className={styles.subject}>{note.subject}</div>
        <div className={styles.body}>{note.body}</div>
      </div>
    </div>
  );
};
