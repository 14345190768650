import styles from './note.module.scss';

type Props = {
  operator?: any;
  isPinned?: boolean;
};

export const NoteOperatorName = ({ operator, isPinned = false }: Props) => {
  return (
    <div className={styles.name}>
      {operator ? (
        `${operator.first_name} ${operator.last_name ? operator.last_name : ''}`
      ) : (
        <span className={styles.name__deleted}>Deleted operator</span>
      )}{' '}
      {isPinned ? <span className={styles.pinned}>Pinned</span> : null}
    </div>
  );
};
