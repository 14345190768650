import { ChangeAvatarPopup } from './ChangeAvatarPopup';
import { avatars } from './utils';
import styles from './avatar.module.scss';

type Props = {
  name: string;
};

export const Avatar = ({ name }: Props) => {
  const [firstName, lastName] = name.split(' ');

  const avatarLS = localStorage.getItem('avatar');
  const avatarLetters = firstName.slice(0, 1) + lastName.slice(0, 1);

  const avatar = avatarLS || avatarLetters;
  const defaultAvatar =
    avatar === avatarLetters ? avatar : avatars.find(({ id }) => id === avatarLS);

  const onSubmit = (close, data) => {
    close();
    localStorage.setItem('avatar', data.avatar);
  };

  return (
    <ChangeAvatarPopup
      defaultAvatar={avatar}
      avatarLetters={avatarLetters}
      onSubmit={onSubmit}
      trigger={
        <button className={styles.avatar}>
          {typeof defaultAvatar === 'string' ? defaultAvatar : <img src={defaultAvatar.src} />}
        </button>
      }
    />
  );
};
