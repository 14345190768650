import API from '../api';

export const getFeedList = (params, id) => API.get(`/api/v1/users/${id}/logs`, { params });

export const getLeadFeedList = (params, id) => API.get(`/api/v1/leads/${id}/logs?${params}`);
export const getOperatorFeedList = (params, id) =>
  API.get(`/api/v1/admin-users/${id}/logs?${params}`);
export const getOperatorsActionList = () => API.get(`/api/v1/logs/action-types-admin-user`);

export const findFeedsMethod = (params, data) => API.post(`/api/v1/search/feeds?${params}`, data);
export const findLogMethod = (params, data) =>
  API.post(`/api/v1/search/activity-log?${params}`, data);

export const getLogActionList = () => API.get('/api/v1/logs/action-types-user');
