import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames/bind';

import styles from './notesTabs.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import { saveNote, updateNote } from 'api/notes';
import { Note } from 'models/Clients/ClientNote';
import { Switch } from 'components/ui/Switch';
import { Col, Container, Row } from 'react-bootstrap';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  noteData?: Note;
  userId?: string;
  type: string;
};

const EditNotePopup = (props: Props) => {
  const { triggerBtn, updateComponent, noteData, userId, type } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isPinned, setIsPinned] = useState(!!noteData?.is_pinned);

  const { register, handleSubmit, control, reset, watch } = useForm({
    reValidateMode: 'onChange',
  });

  const watchField = watch();

  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  const onSubmit = (close, data) => {
    setIsLoading(true);
    const sendData = {
      subject: data && data?.subject ? data.subject : null,
      body: data && data?.body ? data.body : null,
      is_pinned: isPinned === true ? 1 : 0,
    };

    if (type === 'create') {
      saveNote({ ...sendData, user_id: +userId })
        .then((res) => {
          if (res.status === 200) {
            notify({
              type: 'success',
              message: 'Notes edited successfully',
              timeOut: 3000,
            });
            reset();
            closeModal(close);
            updateComponent();
          }
        })
        .catch((error) => {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        })
        .finally(() => setIsLoading(false));
    } else if (type === 'edit') {
      updateNote(sendData, noteData.id)
        .then((res) => {
          if (res.status === 200) {
            notify({
              type: 'success',
              message: 'Notes edited successfully',
              timeOut: 3000,
            });
            reset({
              subject: watchField.subject,
              body: watchField.body,
              is_pinned: isPinned,
            });
            closeModal(close);
            updateComponent();
          }
        })
        .catch((error) => {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
          reset();
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            size="sm"
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={type === 'create' ? 'Add note' : `Edit note`}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'editNotesForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container className={styles.notes}>
                      <Row>
                        <Col md={12}>
                          <div className={cx('notes__subject')}>
                            <div className={cx('label')}>Subject</div>
                            <div className={cx('input-wrap')}>
                              <Controller
                                control={control}
                                name="controller_1"
                                render={() => (
                                  <input
                                    {...register('subject')}
                                    type="text"
                                    name="subject"
                                    defaultValue={noteData?.subject || null}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className={cx('notes__body')}>
                            <div className={cx('label')}>
                              Body<span className={cx('required')}>*</span>
                            </div>
                            <div className={cx('input-wrap')}>
                              <Controller
                                control={control}
                                name="controller_2"
                                render={() => (
                                  <textarea
                                    {...register('body')}
                                    name="body"
                                    defaultValue={noteData?.body || null}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className={cx('notes__footer')}>
                        <div className={cx('notes__info')}>
                          <Switch
                            isChecked={isPinned}
                            onChange={(e) => setIsPinned(e.target.checked)}
                          />
                          <div className={cx('toggle-label')}>Pin</div>
                        </div>
                        <div className={cx('btn-wrap')}>
                          <Button
                            buttonType="outline"
                            buttonText="Cancel"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />

                          <Button
                            isLoading={isLoading}
                            buttonText={'Save'}
                            type="submit"
                            onClick={(data) => handleSubmit(onSubmit.bind(close, data))}
                          />
                        </div>
                      </div>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default EditNotePopup;
