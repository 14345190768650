import moment from 'moment';
import { PinnedNoteName } from './PinnedNoteName';
import styles from './pinned-note.module.scss';

type Props = {
  note: any;
};

export const PinnedNote = ({ note }: Props) => {
  return (
    <div className={styles.note}>
      <div className={styles.subject}>{note?.subject}</div>
      <PinnedNoteName operator={note?.operator} />
      <div className={styles.date}>
        {moment(note?.create_at * 1000).format('DD.MM.YYYY')}{' '}
        {moment(note?.create_at * 1000).format('HH:mm:ss')}
      </div>
      <div className={styles.body}>{note?.body}</div>
    </div>
  );
};
