import { ReactComponent as addFile } from './addFile.svg';
import { ReactComponent as arrowLeft } from './arrowLeft.svg';
import { ReactComponent as arrowRight } from './arrowRight.svg';
import { ReactComponent as arrowsClockwise } from './arrowsClockwise.svg';
import { ReactComponent as arrowsRepeat } from './arrowsRepeat.svg';
import { ReactComponent as bcy } from './bcy.svg';
import { ReactComponent as bell } from './bell.svg';
import { ReactComponent as btc } from './btc.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as call } from './call.svg';
import { ReactComponent as callback } from './callback.svg';
import { ReactComponent as caretDown } from './caretDown.svg';
import { ReactComponent as caretRight } from './caretRight.svg';
import { ReactComponent as caretUp } from './caretUp.svg';
import { ReactComponent as chat } from './chat.svg';
import { ReactComponent as checkCircle } from './checkCircle.svg';
import { ReactComponent as checked } from './checked.svg';
import { ReactComponent as checkedIcon } from './checkedIcon.svg';
import { ReactComponent as client } from './client.svg';
import { ReactComponent as clockCounter } from './clockCounter.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as customerService } from './customerService.svg';
import { ReactComponent as dash } from './dash.svg';
import { ReactComponent as desk } from './desk.svg';
import { ReactComponent as doge } from './doge.svg';
import { ReactComponent as dot } from './dot.svg';
import { ReactComponent as doubleCaretDown } from './doubleCaretDown.svg';
import { ReactComponent as download } from './download.svg';
import { ReactComponent as emailRead } from './emailRead.svg';
import { ReactComponent as emailUnread } from './emailUnread.svg';
import { ReactComponent as eth } from './eth.svg';
import { ReactComponent as eye } from './eye.svg';
import { ReactComponent as eyeClose } from './eyeClose.svg';
import { ReactComponent as hierarchy } from './hierarchy.svg';
import { ReactComponent as indeterminateIcon } from './indeterminateIcon.svg';
import { ReactComponent as lead } from './lead.svg';
import { ReactComponent as logout } from './logout.svg';
import { ReactComponent as ltc } from './ltc.svg';
import { ReactComponent as management } from './management.svg';
import { ReactComponent as minusCircle } from './minusCircle.svg';
import { ReactComponent as moon } from './moon.svg';
import { ReactComponent as notePencil } from './notePencil.svg';
import { ReactComponent as notification } from './notification.svg';
import { ReactComponent as office } from './office.svg';
import { ReactComponent as pause } from './pause.svg';
import { ReactComponent as payment } from './payment.svg';
import { ReactComponent as personalInfo } from './personalInfo.svg';
import { ReactComponent as pipette } from './pipette.svg';
import { ReactComponent as play } from './play.svg';
import { ReactComponent as plusCircle } from './plusCircle.svg';
import { ReactComponent as power } from './power.svg';
import { ReactComponent as resetPassword } from './resetPassword.svg';
import { ReactComponent as rolePermission } from './rolePermission.svg';
import { ReactComponent as search } from './search.svg';
import { ReactComponent as settings } from './settings.svg';
import { ReactComponent as shuffle } from './shuffle.svg';
import { ReactComponent as star } from './star.svg';
import { ReactComponent as sun } from './sun.svg';
import { ReactComponent as team } from './team.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as trx } from './trx.svg';
import { ReactComponent as uncheckedIcon } from './uncheckedIcon.svg';
import { ReactComponent as upload } from './upload.svg';
import { ReactComponent as uploadCloud } from './uploadCloud.svg';
import { ReactComponent as usdc } from './usdc.svg';
import { ReactComponent as usdt } from './usdt.svg';
import { ReactComponent as user } from './user.svg';
import { ReactComponent as wallet } from './wallet.svg';

export const iconSvgs = {
  addFile,
  arrowLeft,
  arrowRight,
  arrowsClockwise,
  arrowsRepeat,
  bcy,
  bell,
  btc,
  calendar,
  call,
  callback,
  caretDown,
  caretRight,
  caretUp,
  chat,
  checkCircle,
  checked,
  checkedIcon,
  client,
  clockCounter,
  close,
  customerService,
  dash,
  desk,
  doge,
  dot,
  doubleCaretDown,
  download,
  emailRead,
  emailUnread,
  eth,
  eye,
  eyeClose,
  hierarchy,
  indeterminateIcon,
  lead,
  logout,
  ltc,
  management,
  minusCircle,
  moon,
  notePencil,
  notification,
  office,
  pause,
  payment,
  personalInfo,
  pipette,
  play,
  plusCircle,
  power,
  resetPassword,
  rolePermission,
  search,
  settings,
  shuffle,
  star,
  sun,
  team,
  trash,
  trx,
  uncheckedIcon,
  upload,
  uploadCloud,
  usdc,
  usdt,
  user,
  wallet,
};
