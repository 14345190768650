import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styles from './../../client-page.module.scss';
import FeedFilter from './FeedFilter';
import { notify } from 'utils/notify';
import { getFeedList, getLogActionList } from 'api/feed';
import FeedTable from 'components/ui/Table/FeedTable';
import TablePagination from 'components/ui/TablePagination';
import { TableMeta } from 'models/Table';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { FeedFields } from './FeedFields/FeedFields';
import { omitBy } from 'lodash';

export const getClientFeedDefaultValues = () => {
  return {
    query: '',
    action_type: { value: 'any', label: 'Any' },
    from: '',
    to: '',
    created_at: '',
  };
};

const Feed = () => {
  const [feedsList, setFeedsList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [filters, setFilters] = useState<any>(null);

  const [actionTypesList, setActionTypesList] = useState([]);
  const [tableMeta, setTableMeta] = useState<TableMeta | undefined>(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const [queryMessage, setQueryMessage] = useState('');

  const { id } = useParams();

  const componentMounted = useRef(true);

  useEffect(() => {
    fetchFeedList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchFeedList = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    const options = {
      perPage: perPageCount,
      ...pageParams,
      ...filtersData,
    };
    const trueOptions = omitBy(options, (value) => !value && value !== 0);

    getFeedList(trueOptions, id)
      .then((res) => {
        // if (componentMounted.current) {
        setFeedsList(res.data.data);
        setTableMeta(res.data.meta);
        setPerPageCount(res.data.meta.per_page);
        setQueryMessage(res.data?.message);
        // }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    fetchFeedList({ navigate: 'first', perPage: 10 });
    setFilters(null);
  };

  const fetchLogactionList = () => {
    getLogActionList().then((res) => {
      if (res && res.status === 200) {
        const result = Object.keys(res.data)
          .map((key) => res.data[key])
          .map((opt) => ({
            value: opt,
            label: opt,
          }));
        setActionTypesList(result);
      }
    });
  };

  useEffect(() => {
    fetchLogactionList();
  }, []);

  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: getClientFeedDefaultValues(),
  });

  return (
    <>
      <div className={styles.tabTitle}>Feed</div>
      <FormProvider {...form}>
        <FeedFields actionTypesList={actionTypesList} />
        <TableLayout
          header={
            <FeedFilter
              onSubmit={(filtersData) => {
                if (!filters && !filtersData) {
                  return;
                }
                if (filters && !filtersData) {
                  clearFiltersSortList();
                } else {
                  setFilters(filtersData);
                  // setPerPageCount((val) => val);
                  fetchFeedList(
                    { page: 1, perPage: filtersData?.filter?.limit || 10 },
                    filtersData,
                  );
                }
              }}
              reloadFilters={isListLoading}
              queryMessage={queryMessage}
            />
          }
        >
          <FeedTable data={feedsList} perPage={perPageCount} showLoader={isListLoading} />
        </TableLayout>
      </FormProvider>
      {feedsList?.length ? (
        <TablePagination
          hidePageSelect={true}
          currentPage={tableMeta?.current_page}
          pagesLength={tableMeta?.last_page}
          perPageChange={(value) => {
            setPerPageCount(value);
            fetchFeedList({ perPage: value }, filters);
          }}
          goToSelectedPage={(page) => {
            fetchFeedList({ page }, filters);
          }}
        />
      ) : null}
    </>
  );
};

export default Feed;
